var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-hair-dryer-outline")]),_vm._v(" Beauty Spots "),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Buscar","placeholder":"Informe o Nome Fantasia ou Razão Social","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscar()},"click:append":function($event){return _vm.buscar()}},model:{value:(_vm.beautySpotPagination.search),callback:function ($$v) {_vm.$set(_vm.beautySpotPagination, "search", $$v)},expression:"beautySpotPagination.search"}}),_c('v-spacer'),_c('v-switch',{staticClass:"mr-3",staticStyle:{"width":"250px"},attrs:{"label":("Exibindo " + (_vm.beautySpots.active? 'Ativos': 'Inativos')),"single-line":"","hide-details":""},on:{"change":function($event){return _vm.buscar()}},model:{value:(_vm.beautySpots.active),callback:function ($$v) {_vm.$set(_vm.beautySpots, "active", $$v)},expression:"beautySpots.active"}}),_c('v-btn',{attrs:{"to":{ name: 'bscreate' },"color":"#3D1159","dark":"","title":"Criar Beauty Spot"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[(!_vm.beautySpotPagination && !_vm.beautySpots)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"warning"}},[_vm._v(" Beauty Spots não encontrados ")]):_vm._e()],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.beautySpots.items),expression:"beautySpots.items"}],staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.beautySpots.items,"options":_vm.options,"server-items-length":_vm.beautySpots.totalItems,"item-key":"id","footer-props":{
                      'items-per-page-options': [10, 25, 50, 75, 100]
                    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#3D1159","icon":"","to":{
                  name: 'beautyspot',
                  params: { id: item.id }
                }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }